import React from "react";
import styled from "styled-components";
import { RichText } from "prismic-reactjs";

import { Headline, Paragraph } from "../type";

export default function MenuItem({
  data: {
    title,
    description,
    petro_description,
    price_per,
    price_min,
    price_max,
    petro_price_min,
    petro_price_max,
  },
}) {
  if (!price_min && !price_per) return null;
  const desc =
    petro_description?.text !== "" ? petro_description?.raw : description?.raw;
  const min = petro_price_min || price_min;
  const max = petro_price_max || price_max;
  return (
    <MenuItemWrapper className="menu-item--row">
      <div>
        {title.text && (
          <Paragraph size="item-title p-large">{title.text}</Paragraph>
        )}
        {description.raw && (
          <div className="item-description">
            <RichText render={desc} />
          </div>
        )}
      </div>

      {/* {min ||
        (price_per && ( */}
      <MenuPrice>
        {min && (
          <MenuDollarWrap>
            {min && <Paragraph size="p-large">${min}</Paragraph>}
            {min && max && <Paragraph size="p-large"> / </Paragraph>}

            {max && <Paragraph size="p-large">${max}</Paragraph>}
          </MenuDollarWrap>
        )}
        <Paragraph size="p-small">
          {min && (
            <>
              —<br />
            </>
          )}
          {price_per}
        </Paragraph>
      </MenuPrice>
      {/* ))} */}
    </MenuItemWrapper>
  );
}

const MenuPrice = styled.div`
  display: grid;
  align-items: start;
  justify-items: end;
  grid-auto-flow: rows;
  grid-template-rows: repeat(3, min-content);
  gap: 0.5rem;

  @media print {
    gap: 0rem;
  }

  p {
    margin: 0;
    text-align: end;
  }
`;

const MenuDollarWrap = styled.div`
  display: inline-grid;
  grid-auto-flow: column;
  gap: 0.25rem;
`;

const MenuItemWrapper = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  grid-template-rows: auto;
  gap: 1rem;
  page-break-before: always;
  page-break-inside: avoid;

  .item-title {
    margin-bottom: 0.5rem;
  }

  .item-description {
    max-width: 80%;

    & h1,
    & h2,
    & h3,
    & h4,
    & h5 {
      font-size: ${({ theme }) => theme.fonts.paragraphLarge};
      margin-bottom: 0.5rem;
    }

    & p,
    & ul li {
      font-size: ${({ theme }) => theme.fonts.paragraphSmall};
      margin-bottom: 0.5rem;
    }

    ul {
      list-style: square;
      padding-left: 1rem;
    }
  }
`;
