/**
 * Vendor
 */
import React from "react";
import Img from "gatsby-image";
import styled from "styled-components";
import { ParallaxBanner } from "react-scroll-parallax";

import Box from "./john-motion/motion-box/index";
import ImageBox from "./john-motion/motion-box/image-box";

const OutlineImageBoxWrap = styled(Box)`
  --height: 45vw;
  --radius-top: calc(1rem + 2.5vw);
  --radius-bottom: calc(1rem + 2.5vw);
  --border: 3px;
  height: var(--height);
  background: ${({ theme }) => theme.colors.black};
  border-top-left-radius: var(--radius-top);
  border-top-right-radius: var(--radius-top);
  border-bottom-left-radius: var(--radius-bottom);
  border-bottom-right-radius: var(--radius-bottom);
  border: var(--border) solid ${({ theme }) => theme.colors.golden};
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  .parallax-banner {
    --radius-top-inner: calc(var(--radius-top) - var(--border));
    --radius-bottom-inner: calc(var(--radius-bottom) - var(--border));
    border-top-left-radius: var(--radius-top-inner);
    border-top-right-radius: var(--radius-top-inner);
    border-bottom-left-radius: var(--radius-bottom-inner);
    border-bottom-right-radius: var(--radius-bottom-inner);
    overflow: hidden;
  }

  > * {
    position: relative;
    z-index: 1;
  }

  .children-wrapper,
  .image-wrapper {
    position: absolute !important;
    top: 0;
    left: 0;
    width: 100% !important;
    height: 100% !important;
  }

  .image {
    position: absolute !important;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
  }
`;

export default function OutlineBoxPlain({
  fluid,
  children,
  parallaxAmount,
  hiddenStyles,
  controls,
  ...rest
}) {
  return (
    <OutlineImageBoxWrap hiddenStyles={{ x: -10, ...hiddenStyles }} {...rest}>
      {children}
    </OutlineImageBoxWrap>
  );
}
