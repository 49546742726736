import { motion, useViewportScroll } from "framer-motion";
import React from "react";
import styled from "styled-components";
import SvgLoader from "../svg-loader";

const ArrowWrap = styled(motion.a)`
  position: fixed;
  bottom: calc(var(--outer-rail) / 2);
  left: calc(var(--outer-rail) / 2);
  z-index: 99;

  svg {
    width: 40px;
    height: 40px;
  }
`;

export default function MenuArrowScroll() {
  const { scrollY } = useViewportScroll();
  const [min, setMin] = React.useState(false);

  function update() {
    if (scrollY?.current < scrollY?.prev) {
      if (scrollY?.current < 300) {
        setMin(false);
      }
    } else if (scrollY?.current > 300 && scrollY?.current > scrollY?.prev) {
      setMin(true);
    }
  }

  React.useEffect(() => scrollY.onChange(() => update()));

  const wrapperVariants = {
    pre: {
      opacity: 0,
      y: 20,
      x: "-50%",
      pointerEvents: "none",
    },
    visible: { opacity: 1, y: 0, x: "-50%", pointerEvents: "fill" },
    hover: { opacity: 1, y: -5, x: "-50%", pointerEvents: "fill" },
  };

  return (
    <ArrowWrap
      href="#main"
      initial="pre"
      animate={min ? "visible" : "pre"}
      variants={wrapperVariants}
      whileHover="hover"
      transition={{ ease: [0.1, 0.25, 0.3, 1], duration: 0.4 }}
      data-min={min}
      data-no-print
    >
      <SvgLoader icon="ArrowUp" />
    </ArrowWrap>
  );
}
