import React from "react";
import styled from "styled-components";
import { slugify } from "../../functions/util";
import { Wrapper } from "../layout-components";
import { PillWrapper } from "../globals/pill-wrapper";
import { Paragraph } from "../type";

const MenuNavWrapper = styled(Wrapper)`
  display: none;

  /* NEED TO FIX FOR MOBILE */
  @media only screen and ${({ theme }) => theme.device.tablet} {
    display: grid;
    align-items: center;
    justify-items: center;
  }
`;

const MenuNavGroup = styled(PillWrapper)`
  gap: 2rem;

  p {
    font-size: ${({ theme }) => theme.fonts.titleLarge};
    line-height: 130%;
    text-align: center;
    letter-spacing: 0.2em;
    text-transform: uppercase;
    background-color: transparent;
    margin: 0;
  }
`;

const Link = styled.a`
  &:after {
    content: "";
    width: 95%;
    height: 1px;
    position: absolute;
    bottom: 0;
    left: 0px;
    background: ${({ theme }) => theme.colors.golden};
    transition: transform ${({ theme }) => theme.animation.duration[100].css};
    transition-timning-function: ${({ theme }) =>
      theme.animation.timingFunction.css};
    transform-origin: right top;
    transform: scaleX(0);
  }

  &:hover {
    text-decoration: none;

    &:after {
      transform: scaleX(1) scaleY(2.5);
      transform-origin: left top;
    }
  }
`;

function MenuSectionNav({ group }) {
  if (group.length <= 1) return null;
  return (
    <MenuNavWrapper data-no-print>
      <MenuNavGroup color="blackOutline">
        {group.map(({ menu_link }, i) => {
          const section = menu_link.document?.data;
          const sectionLink = section.page_title && slugify(section.page_title);
          return (
            <Paragraph size="h6" key={i}>
              <Link href={`#${sectionLink}`}>{section.page_title}</Link>
            </Paragraph>
          );
        })}
      </MenuNavGroup>
    </MenuNavWrapper>
  );
}

export default MenuSectionNav;
