import React from "react";
import styled from "styled-components";
import { RichText } from "prismic-reactjs";

import { GridWrapper, Wrapper } from "../layout-components";
import { Headline, Paragraph } from "../type";
import { isEvenOdd, slugify } from "../../functions/util";
import MenuItem from "./menu-item";
import SplitScrollSection from "../split-scroll-section";
import OutlineBox from "../outline-box-plain";
import Box from "../john-motion/motion-box";

const MenuPanel = styled(OutlineBox)`
  height: 86vh;
  width: 100%;
  background: ${({ theme }) => theme.colors.golden};
  color: ${({ theme }) => theme.colors.black};
  flex-direction: column;
  text-align: center;
  padding: 6vw;
  margin: 4vw 0;
  page-break-before: always;
  page-break-inside: avoid;

  a {
    color: ${({ theme }) => theme.colors.black};
  }

  p {
    line-height: 150%;
    white-space: pre-line;
  }
`;

const MenuItemPanel = styled(Box)`
  margin: 4rem 4vw 0;

  h4 {
    font-family: "DomaineSansTextRegular";
    font-size: ${({ theme }) => theme.fonts.paragraphLarge};
  }

  ul {
    margin-bottom: 1rem;
  }

  @media print {
    opacity: 1 !important;

    .menu-item--title {
      margin-bottom: 1rem;
    }

    .menu-item--panel {
      margin: 0 2rem 0;
    }
  }
`;

const MenuItemPanelAnchor = styled.div`
  &:target {
    display: block;
    position: relative;
    top: -100px;
    visibility: hidden;
  }
`;

const MenuItemWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 2rem;

  @media print {
    gap: 1rem;
  }

  .item-caption {
    p {
      font-size: ${({ theme }) => theme.fonts.paragraphSmall};
    }
  }
`;

const MenuSectionTitle = styled.div`
  margin-bottom: 4rem;
`;

const MenuSectionAnchor = styled.div`
  &:target {
    display: block;
    position: relative;
    top: -100px;
    visibility: hidden;
  }
`;

const LinkTop = styled.a`
  position: absolute;
  top: 2rem;
`;

export default function MenuSection({ group }) {
  return (
    <>
      {group.map(({ menu_link }, i) => {
        const section = menu_link.document?.data;
        return (
          <SplitScrollSection
            key={i}
            layout={isEvenOdd(i) === "even" ? "default" : "reverse"}
            content={section.body.map(({ items, primary }, groupIndex) => {
              const prim_title = primary.petro_title.text || primary.title.text;
              const prim_desc =
                primary.petro_description.text !== ""
                  ? primary.petro_description.raw
                  : primary.description.raw;

              return (
                <MenuItemPanel
                  key={groupIndex}
                  className="menu-item--panel"
                  transitionOverrides={{
                    duration: 0.3,
                  }}
                  hiddenStyles={{
                    x: 0,
                  }}
                >
                  <MenuItemPanelAnchor
                    id={section.page_title && slugify(section.page_title)}
                  />
                  <MenuSectionTitle className="menu-item--title">
                    {prim_title && (
                      <Headline tag="h3" size="h4">
                        <MenuSectionAnchor
                          id={prim_title && slugify(prim_title)}
                        />
                        <a href={`#${prim_title && slugify(prim_title)}`}>
                          {prim_title}
                        </a>
                      </Headline>
                    )}
                    {prim_desc && <RichText render={prim_desc} />}
                  </MenuSectionTitle>
                  <MenuItemWrapper className="menu-item--item-group">
                    {items &&
                      items.map((item, itemIndex) => (
                        <MenuItem key={itemIndex} data={item} />
                      ))}
                    {primary.caption.text !== "" && (
                      <div className="item-caption">
                        <RichText render={primary.caption.raw} />
                      </div>
                    )}
                  </MenuItemWrapper>
                </MenuItemPanel>
              );
            })}
          >
            <MenuPanel className="menu-panel">
              {i > 0 && (
                <LinkTop href="#main" data-no-print>
                  Top
                </LinkTop>
              )}
              <Headline tag="h3" size="h3">
                <a
                  href={`#${section.page_title && slugify(section.page_title)}`}
                >
                  {section.page_title}
                </a>
              </Headline>
              {section.page_description.raw && (
                <RichText render={section.page_description.raw} />
              )}
              {section.page_disclaimer.raw && (
                <RichText render={section.page_disclaimer.raw} />
              )}
            </MenuPanel>
          </SplitScrollSection>
        );
      })}
    </>
  );
}
