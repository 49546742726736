import React from "react";
import { graphql } from "gatsby";

import { MenuSection } from "../components/menu";
import MenuSectionNav from "../components/menu/menu-section-nav";
import PageMarquee from "../components/page-marquee";
import MenuArrowScroll from "../components/menu/menu-arrow-scroll";

/**
 * page-template Component
 *
 * @param {Object} props
 * @param {Object} props.data
 */
export default function Template({
  data: {
    prismicMenusMenuCollection: { data, uid },
    prismicMenuIndex: {
      data: { menu_cover },
    },
  },
}) {
  const matchKeyInArray = (array, key, value) =>
    array.find((item) => item[key] === value);

  const match = matchKeyInArray(menu_cover, "key", data.path);

  return (
    <>
      <PageMarquee
        title={data.page_title}
        titleSize="h2"
        titleLineLength={0.75}
        description={data.page_description}
        caption={data.page_disclaimer}
        actionText="Print this Menu"
        actionOnClick={() => window.print()}
        actionStyle="nude"
        layout="default"
        image={match?.image}
      />

      <MenuSectionNav group={data.group} />
      <MenuSection group={data.group} />
      <MenuArrowScroll />
    </>
  );
}

/**
 * pageQuery
 */
export const query = graphql`
  query ($uid: String!) {
    prismicMenusMenuCollection(uid: { eq: $uid }) {
      id
      uid
      tags
      data {
        page_title
        page_description
        page_disclaimer
        path
        group {
          menu_link {
            id
            document {
              ... on PrismicMenusMenu {
                id
                data {
                  page_title
                  page_description {
                    raw
                  }
                  page_disclaimer {
                    raw
                  }
                  body {
                    ... on PrismicMenusMenuDataBodyMenuGroup {
                      id
                      primary {
                        title {
                          raw
                          text
                        }
                        petro_title {
                          raw
                          text
                        }
                        catering_title {
                          raw
                          text
                        }
                        description {
                          raw
                          text
                        }
                        petro_description {
                          raw
                          text
                        }
                        catering_description {
                          raw
                          text
                        }
                        caption {
                          raw
                          text
                        }
                      }
                      items {
                        title {
                          text
                          raw
                        }
                        price_per
                        price_min
                        price_max
                        description {
                          text
                          raw
                        }
                        petro_price_min
                        petro_price_max
                        petro_description {
                          text
                          raw
                        }
                        catering_price_min
                        catering_price_max
                        catering_description {
                          text
                          raw
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    prismicMenuIndex {
      id
      data {
        menu_cover {
          key
          image {
            alt
            fluid(
              placeholderImgixParams: { blur: 1600, px: 1 }
              maxWidth: 2200
            ) {
              ...GatsbyPrismicImageFluid
            }
          }
        }
      }
    }
  }
`;
